.mat-drawer-backdrop {
  position: fixed !important;
}

.mat-drawer {
  position: fixed !important;
  top: 80px !important;
}

.mat-drawer-container {
  background-color: #fff;
}

