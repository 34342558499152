.main-container section.main-section {
  background: white;
  max-width: calc(100% - 60px);
  margin: 24px 40px;
  padding-right: 10px;
  min-height: 78vh;
  display: flex;
  height: 100%;
  width: 100%;
  .container{margin-left: 0;}
  .card {
    &.parent {
      border: 0;
      border-radius: 0;
      background: transparent;
      > .card-header {
        padding: 11px 0 9px 0;
        min-height: 56px;
        background: transparent;
        border-color: #e1e3ea;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          margin: 0;
          font-size: 17px;
          font-weight: 600;
        }
        .card-actions {
          display: flex;
          align-items: center;
      }
      }
      > .card-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
